html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.footer {
  /* border-top: 1px solid rgb(126, 126, 126); */
  font-size: small;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  max-width: 1500px;
  margin: auto;
}

.footer-mobile {
  font-size: small;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  max-width: 1500px;
  margin: auto;
}

.footer-container {
  border-top: 1px solid rgb(126, 126, 126);
  padding-top: 30px;
}

.container {
  max-width: 100%;
  margin: auto !important;
}

.page-content {
  padding-top: 64px;
  padding-left: 10px;
  padding-right: 10px;
  /* padding-left: 50px; */
  /* padding-right: 50px; */
  max-width: 1500px;
  margin: auto;
  margin-top: 54px;
  padding-bottom: 20px;
  min-height: 1500px;
}

.page-container {
  max-width: 1500px;
}


/* 
// Ant.design
xs < 576px
sm >= 576px
md >= 768px
lg >= 992px
xl >= 1200px
xxl >= 1600px 

// meterial-ui
xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px

*/

@media only screen and (max-width: 900px) {

  .page-container {
  
  }

  .page-content {
    /* padding-left: 50px; */
    /* padding-right: 50px; */
    /* max-width: 990px; */
    margin: auto;
    margin-top: 14px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .show-on-desktop-only {
    display: none;
  }
}

@media only screen and (min-width: 900px) {
  .show-on-mobile-only {
    display: none;
  } 
}


.ant-page-header-content {
  padding-top: 0px;
}
.ant-select-dropdown {
  z-index: 1000000000;
  border: 1px solid black;
}

.full-image-class{
  height: 400px;
  max-width: 100%;
}

.full-image-class img{
  height: 400px;
  max-width: 100%;
}

.css-nnbavb {
  display: none;
}


.search-by-options-selector .ant-select-selector {
  background-color: #f8e8de !important;
  border-radius: 0 !important;
}

h1 {
  font-size: x-large !important;
}

h2 {
  font-size: medium !important;
}


/* use css to set position of ant modal https://2x.ant.design/components/modal/*/
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}

.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1202;
}

.ant-modal-wrap {
  z-index: 9998;
}

.leaflet-popup-content-wrapper {
  color: #0071cb !important;
  background-color: #e6f7ff !important;
  font-family: 'Noto Sans Thai';
}

.leaflet-popup-content {
  text-align: center !important;
  width: max-content !important;
  max-width: 250px !important;
}

.leaflet-popup-tip {
  background-color: #e6f7ff !important;
}

.leaflet-geosearch-bar {
  max-width: none !important;
  width: 90% !important;
}

.ant-picker-dropdown {
  z-index: 9999;
}

.ant-select-dropdown {
  z-index: 9999;
}

.requirement-root-container {
  position: fixed;
  width: 100%;
  height: calc(100% - 56px);
  top: 0;
  left: 0;
  background: white;
  overflow: scroll;
  z-index: 1202;
}

.theme-provider {
  max-height: 100vh !important;
  overflow: hidden !important;
}

.news-detail img{
  max-width: 100% !important;
  height: auto !important;
}

.news-detail .ql-align-center{
  text-align: center !important;
}
.news-detail h3, .news-paragrapph-detail h3{
  font-size: 14.5px !important;
}